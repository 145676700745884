import React, { useState, useLayoutEffect } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useApi } from '~/api'
import Slider from 'react-slick'
import ReactLightbox from './Lightbox'
import { scroller } from 'react-scroll'
import cn from 'classnames'
import * as st from '~/assets/styl/ServicesDetails.module.styl'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'
import { LazyImage } from 'react-lazy-images'

type Props = {
  pageContext: {
    name: string
    slug: string
    icon: string
    image: string
    introduction: string
    button_text: string
    button_link: string
    products: {
      name: string
      slug: string
      text: string
      buttonText: string
      buttonLink: string
      buttonExternal: string
      images: { original: string; thumb: string }[]
    }[]
  }
}

export default function ServicesDetails({
  pageContext: {
    name,
    icon,
    image,
    introduction,
    button_link,
    button_text,
    products,
  },
}: Props) {
  const serviceCards = useApi(
    useStaticQuery(graphql`
      query {
        allServices {
          nodes {
            name
            slug
            icon
            button_text
            button_link
            introduction
          }
        }
      }
    `).allServices.nodes,
    'services'
  )

  const [serviceMenuOpen, setServiceMenuOpen] = useState(false)
  const [imagesZoom, setImagesZoom] = useState<Array<string>>()
  const [index, setIndex] = useState(0)
  const pathname = typeof window !== 'undefined' ? window.location.pathname : ''

  function handleServiceMobileMenu() {
    setServiceMenuOpen(!serviceMenuOpen)
  }

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
  }

  useLayoutEffect(() => {
    if (window.location.hash) {
      const targetEl: HTMLElement = document.querySelector(window.location.hash)

      if (targetEl) {
        const f = setInterval(() => {
          const scrollY = targetEl.offsetTop

          if (document.body.offsetHeight >= scrollY) {
            scroller.scrollTo(window.location.hash.split('#')[1], {
              offset: -115,
            })
            clearInterval(f)
          }
        }, 50)
      }
    }
  }, [])

  return (
    <div className={st.servicesDetailsContainer}>
      <aside className="hide-on-mobile">
        <ul>
          {serviceCards.map((card, index) => (
            <Link
              key={index}
              to={'/servicos/' + card.slug + '/'}
              title={card.title}
              activeClassName="active"
            >
              <li>
                <img src={card.icon} alt={card.name} />
                <h2>{card.name}</h2>
              </li>
            </Link>
          ))}
        </ul>
      </aside>

      <div className={cn(st.serviceMobileMenu, 'hide-on-desktop')}>
        <p>Selecione abaixo o serviço que você deseja:</p>
        <button onClick={handleServiceMobileMenu} type="button">
          <div>
            <img src={icon} /> {name}
          </div>
          <span className="icon-right-control"></span>
        </button>
        <ul className={serviceMenuOpen && st.active}>
          {serviceCards.map((card, index) => (
            <Link
              key={index}
              to={'/servicos/' + card.slug + '/'}
              title={card.title}
            >
              <li>
                <p>{card.name}</p>
                <span className="icon-right-control"></span>
              </li>
            </Link>
          ))}
        </ul>
      </div>

      <div className={st.serviceWrapper}>
        <div
          className={cn(st.serviceBanner, {
            [st.backgroundMissingImage]: !image,
          })}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className={st.bannerDescription}>
            <img src={icon} alt={name} />
            <p>{name}</p>
          </div>
        </div>

        <div className={st.serviceDescription}>
          {introduction && (
            <section>
              <h2>Sobre</h2>
              <p>{introduction}</p>
              {button_link && (
                <a className={st.infos} href={button_link}>
                  {button_text}
                </a>
              )}
            </section>
          )}

          <section className={st.shareWrapper}>
            <h2>Compartilhar</h2>
            <ul>
              <li>
                <WhatsappShareButton
                  url={`https://www.bauerturismo.com${pathname}`}
                >
                  <span className="icon-whatsapp"></span>
                </WhatsappShareButton>
              </li>
              <li>
                <FacebookShareButton
                  url={`https://www.bauerturismo.com${pathname}`}
                >
                  <span className="icon-facebook"></span>
                </FacebookShareButton>
              </li>
              <li>
                <LinkedinShareButton
                  url={`https://www.bauerturismo.com${pathname}`}
                >
                  <span className="icon-linkedin"></span>
                </LinkedinShareButton>
              </li>
              <li>
                <TwitterShareButton
                  url={`https://www.bauerturismo.com${pathname}`}
                >
                  <span className="icon-twitter"></span>
                </TwitterShareButton>
              </li>
            </ul>
          </section>
        </div>

        <div>
          {(products || []).map((product, index) => (
            <div key={index} className={st.serviceDetails} id={product.slug}>
              <aside>
                <section>
                  <h2>{product.name}</h2>

                  {name === 'Fretamento e Viagens' && (
                    <section className={st.shareWhatsApp}>
                      <div className={st.item}>
                        <WhatsappShareButton
                          url={`${product.name}: https://www.bauerturismo.com${pathname}#${product.slug}`}
                        >
                          <span className="icon-whatsapp"></span>
                          <span className={st.titleShareWhatsApp}>
                            Compartilhar
                          </span>
                        </WhatsappShareButton>
                      </div>
                    </section>
                  )}
                </section>
              </aside>

              <div className={st.serviceDetailsContent}>
                <p>{product.text}</p>

                <div className={st.sliderWrapper}>
                  <div className="hide-on-mobile">
                    <Slider {...settings} className={st.slick}>
                      {product.images.map((image, key) => (
                        <div
                          className={st.cardImage}
                          onClick={() => {
                            setIndex(index)
                            setImagesZoom(product.images.map((i) => i.original))
                          }}
                          key={key}
                        >
                          <LazyImage
                            src={image.thumb}
                            alt="Imagem"
                            placeholder={({ ref }) => (
                              <img ref={ref} src={image.thumb} alt="Imagem" />
                            )}
                            actual={({ imageProps }) => (
                              <img {...imageProps} style={{ width: '100%' }} />
                            )}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>

                  <div
                    className={cn(st.mobileGallery, 'hide-on-desktop', {
                      [st.oneImage]: product.images.length == 1,
                      [st.twoImages]: product.images.length == 2,
                    })}
                  >
                    {product.images.map((image, key) => (
                      <img
                        key={key}
                        src={image.thumb}
                        alt="Imagem"
                        onClick={() => {
                          setIndex(index)
                          setImagesZoom(product.images.map((i) => i.original))
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <ReactLightbox
        index={index}
        images={imagesZoom}
        setIndex={setIndex}
        setImages={setImagesZoom}
      />
    </div>
  )
}
