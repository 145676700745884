import React from 'react'
import { Head } from '~/components'
import { useApi } from '~/api'
import Banner from '~/components/Banner'
import Breadcrumb from '~/components/Breadcrumb'
import Services from '~/components/Services'
import VipLounge from '~/components/VipLounge'
import Footer from '~/components/Footer'
import ServicesDetails from '~/components/ServicesDetails'
import { Router } from '@reach/router'

import bannerServicos from '~/assets/img/banner-servicos.png'

const ServicesWrapper = (props) => (
  <>
    <Head title={'Serviços - ' + process.env.GATSBY_SITE_NAME} />
    <Banner
      bannerBackgroundColor="#BCBCBC"
      bannerTitle="Serviços que facilitam a sua viagem"
      bannerImage={bannerServicos}
      bannerImageAlt="Foto de um ônibus e o logo da Bauer Turismo"
      imageBottomPosition={20}
    />
    <Breadcrumb />
    <Services />
    <VipLounge />
    <Footer />
  </>
)

const DetailsWrapper = ({ pageContext, slug }) => {
  const context = useApi(pageContext, 'services/' + (pageContext.slug || slug))
  const { name } = context
  return (
    <>
      <Head title={name + ' - ' + process.env.GATSBY_SITE_NAME} />
      <Banner
        bannerBackgroundColor="#BCBCBC"
        bannerTitle="Serviços que facilitam a sua viagem"
        bannerImage={bannerServicos}
        bannerImageAlt="Foto de um ônibus e o logo da Bauer Turismo"
        imageBottomPosition={20}
      />
      <Breadcrumb />
      <ServicesDetails pageContext={context} />
      <Footer />
    </>
  )
}

const Servicos = (props) => (
  <Router>
    <ServicesWrapper {...props} path="/servicos/" />
    <DetailsWrapper {...props} path="/servicos/:slug" />
  </Router>
)

export default Servicos
