// extracted by mini-css-extract-plugin
export var active = "ServicesDetails-module--active--gCUM-";
export var backgroundMissingImage = "ServicesDetails-module--background-missing-image--21s2B";
export var bannerDescription = "ServicesDetails-module--banner-description--sUCJA";
export var cardImage = "ServicesDetails-module--card-image--p90oI";
export var controls = "ServicesDetails-module--controls--9W2HP";
export var disabled = "ServicesDetails-module--disabled--z3cFW";
export var infos = "ServicesDetails-module--infos--2dK6G";
export var item = "ServicesDetails-module--item--Vx6Rd";
export var mobileGallery = "ServicesDetails-module--mobile-gallery--28aAb";
export var oneImage = "ServicesDetails-module--one-image--aE1D1";
export var serviceBanner = "ServicesDetails-module--service-banner--BNy0d";
export var serviceDescription = "ServicesDetails-module--service-description--zu0aE";
export var serviceDetails = "ServicesDetails-module--service-details--ySFWF";
export var serviceDetailsContent = "ServicesDetails-module--service-details-content--QvPRL";
export var serviceMobileMenu = "ServicesDetails-module--service-mobile-menu--PkfXZ";
export var serviceWrapper = "ServicesDetails-module--service-wrapper--YWbXk";
export var servicesDetailsContainer = "ServicesDetails-module--services-details-container--p77Tq";
export var shareWhatsApp = "ServicesDetails-module--shareWhatsApp--G1Tr1";
export var shareWrapper = "ServicesDetails-module--share-wrapper--6ic9J";
export var slick = "ServicesDetails-module--slick--bowHY";
export var sliderWrapper = "ServicesDetails-module--slider-wrapper--fZxTC";
export var titleShareWhatsApp = "ServicesDetails-module--titleShareWhatsApp--blNX7";
export var twoImages = "ServicesDetails-module--two-images--I4vLq";